.sidebar {

	.block-title {
		font-size: 18px;
		font-weight: bold;
		text-transform: uppercase;
		padding: 26px 0;
		position: relative;
		background: color('gray', 'semi-light');
		border: 1px solid color('gray', 'semi-light');

		&:before,
		&:after {
			content: '';
			position: absolute;
			left: -1000%;
			height: 100%;
			width: 1000%;
			background: color('gray', 'semi-light');
		}

		&:before {
			top: -1px;
		}

		&:after {
			bottom: -1px;
		}

	}

	.block-content {

		h5 {
			display: block;
			margin: 20px 0;
			font-size: 20px;
			font-weight: bold;
		}

		li {
			line-height: 26px;
			font-size: 18px;
			display: block;	
			margin: 10px 0;				
		}

	}

}