.checkout-onepage-index {

    .col2-left-layout {

		.main {
			padding: 0;
			margin-bottom: 100px;
		}

        .col-left {
            @include span-columns(3.3);
            background: color('gray', 'semi-light');
            padding: 25px 20px;
        }

        .col-main {
            @include span-columns(8.7);
            margin-right: 0;
        }

    }

	.page-title {
		@include span-columns(12);
		margin: 40px 0 30px 0;

		h1 {
			@include span-columns(3.3);
			font-size: 24px;
			color: color('orange');
			padding: 5px 0;

			span {
				margin-right: 10px;
			    position: relative;
			    top: 3px;				
			}

		}

		.steps {
			@include span-columns(8.7);
		}

	}

	.block-progress {
		@import "components/checkout/progress.scss";
	}

	.opc {
		@include span-columns(12);
		@import "components/checkout/opc.scss";
	}

}