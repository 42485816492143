.banners-subscription {
	background: color('gray', 'semi-light');

	.content {
		@include outer-container;
		padding: 60px $gapp;

		.subscription-title {
			@include span-columns(12);
			padding-bottom: 15px;
			text-transform: uppercase;
			color: color('orange');
			border-bottom: 1px solid color('gray');
			position: relative;

			h2 {
				font-size: 24px;					
			}

		}

		.swiper-container {
			@include span-columns(12);
			margin: 40px 0 0 0;

			.swiper-slide {

				li {

					&:nth-child(1),
					&:nth-child(3) {
						@include span-columns(3);
					}

					&:nth-child(2) {
						@include span-columns(6);
					}

				}

			}

		}

	}

}
