span, a {
	color: color('white');
	display: inline-block;
	vertical-align: middle;
	padding-top: 12px;
}

span {
	font-size: 30px;
	margin-right: 10px;
}

a {
	
	&:hover {
		color: rgba(color('white'), 0.6);
	}

}