.bar {
	@include span-columns(12);
	padding: 12px 0;
	background: color('gray', 'light');
	text-align: center;
	border-bottom: 1px solid color('gray');

	li {
		display: inline-block;
		vertical-align: middle;
		padding: 10px 40px;
		text-transform: uppercase;
		border-right: 1px solid color('gray');

		&:last-child {
			border-right: 0;
		}

		span {
			color: color('orange');
			margin-right: 20px;
			font-size: 24px;
			vertical-align: middle;
		}

	}

}