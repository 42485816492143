.page-header {
	background: color('black');
	color: color('white');

	&.fixed {
		@import "components/header/fixed.scss";
		position: fixed;
		z-index: 10;
		left: 0;
		right: 0;
	}

	.page-header-container {
		@include outer-container;
		padding: 18px 0;
		position: relative;

		.header-logo {
			@include span-columns(2);
		}

		.header-search {
			@include span-columns(5.8);
			@import "components/header/search.scss";
		}

		.header-info {
			@include span-columns(1.5);
			@import "components/header/info.scss";
		}	
		
		.header-account {
			@include span-columns(1.8);
			@import "components/header/account.scss";
		}		

		.header-cart {
			@include span-columns(1);
			@import "components/header/cart.scss";
		}									

	}

	.header-nav {
		@include span-columns(12);
		@include box-shadow();		
		background: color('white');
		border-bottom: 1px solid color('orange');
		@import "components/header/nav.scss";
	}

	.header-secure {
		@include span-columns(2);
		float: right;
		font-size: 14px;
		text-transform: uppercase;
		text-align: right;
		padding: 8px 0;

		span, p {
			@include span-columns(6);
		}

		span {
			font-size: 30px;
			line-height: 34px;
		}

		p {
			text-align: left;
			line-height: 18px;
			padding-left: 10px;
		}

	}

}