.blog-index-list,
.blog-post-view,
.blog-cat-view {

	.toolbar {
		@include span-columns(12);
		@import "components/category/toolbar.scss";
		margin: 0 0 20px 0;

		.col-1 {
			@extend .no-display;
		}

		.col-2 {
			float: right;
		}

	}

	@import "components/blog/sidebar.scss";
	@import "components/blog/post.scss";
}
