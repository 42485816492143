$black: (
    "light" : #383838,
    "base"  : #000000
);

$white: (
    "base" : #ffffff
);

$blue: (
    "light": #51c4d4,
    "base" : #075b76
);

$orange: (
    "base" : #ffa12d
);

$gray: (
    "light"      : #fbfbfb,
    "semi-light" : #f4f4f4,
    "base"       : #ebebeb,
    "dark"       : #8b8b8b
);

$green: (
    "base" : #48b80b
);

$yellow: (
    "base" : #fcc100
);

$red: (
    "base" : #f9061a
);

$colors: (
    "black"  : $black,
    "white"  : $white,
    "orange" : $orange,
    "gray"   : $gray,
    "yellow" : $yellow,
    "red"    : $red,
    "green"  : $green,
    "blue"   : $blue
);

