.page-header-container {
	padding: 6px 0 6px 0;

	.header-logo {

		img {
			width: 90%;
		}

	}

	.header-account {

		.hello {
			width: 55%;
			padding-top: 18px;

			b {
				display: inline-block;
			}

			.box {
				left: -67px;
				margin-top: 10px;
			}

		}

	}

	.header-cart {

		.count {
			top: 20%;
		}

		.icon-cart {
			padding: 16px 30px;
		}

	}

}


.header-nav {
	@extend .no-display;
}
