%swiper-pagination {
    background: rgba(color('gray'), 0.2);

    &.swiper-pagination-bullet-active {
        background: color('orange');
    }

}

%swiper-navigation {
    color: color('white');
}

%swiper-navigation-banner {
    background: none;
}

.swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    z-index: 1;

    .swiper-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 1;
        padding: 0;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-transition-property: -webkit-transform;
        -moz-transition-property: -moz-transform;
        -o-transition-property: -o-transform;
        -ms-transition-property: -ms-transform;
        transition-property: transform;
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;
    }

    .swiper-slide {
        -webkit-flex-shrink: 0;
        -ms-flex: 0 0 auto;
        flex-shrink: 0;
        width: 100%;
        height: 100%;
        position: relative;

        .item {
            width: 90%;
            margin: 10px auto;
            float: none;

            .actions {

                a {
                    font-size: 14px;

                    span {
                        font-size: 12px;

                        &.icon-eye {
                            font-size: 14px;
                        }

                    }   

                }
            
            }

            img {
                width: auto;
            }

        }

        img {
            width: 100%;
        }

    }

}

.swiper-pagination {
    position: absolute;
    text-align: center;
    z-index: 2;
    left: 0;
    right: 0;
    bottom: 0;

    &.top {
        top: 5px;
        left: auto;
        bottom: auto;
    }

    .swiper-pagination-bullet {
        display: inline-block;
        vertical-align: middle;
        height: 10px;
        width: 10px;
        margin: 0 8px;
        cursor: pointer;
        @include border-radius(50%, 50%, 50%, 50%);
        @extend %swiper-pagination;

        &:last-child {
            margin-right: 0;
        }

    }

}

.swiper-button-prev,
.swiper-button-next {
    position: absolute;
    top: 50%;
    z-index: 2;
    cursor: pointer;
    font-family: 'icomoon';
    font-size: 20px;
    text-align: center;
    color: color('black');
    @extend %swiper-navigation;

    &.white {
        width: 45px;
        height: 45px;      
        line-height: 45px;
        background: color('white');  
        @include border-radius(50%, 50%, 50%, 50%);

        &:hover {
            background: rgba(color('white'), 0.6);
        }

    }

    &.swiper-button-disabled {

        &.white {
            background: rgba(color('white'), 0.2);
        }
        
        color: rgba(color('gray', 'dark'), 0.2);
    }

}

.swiper-button-prev {
    left: 0;
    transform: rotate(180deg);
    @extend .icon-navigation;

    &.white {
        left: $gapp;
    }

}

.swiper-button-next {
    right: 0;
    @extend .icon-navigation;

    &.white {
        right: $gapp;
    }

}
